<template>
  <div id="CriteriaRegister">
    <button
      @click="add()"
      style="background-color: #1d425e; color: white"
      class="btn font-weight-bold w-100"
    >
      Añadir Critero de calificación
    </button>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Añadir criterio de calificación"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="error" />
            <div class="form-group my-3">
              <label for="exampleInputEmail1">Criterio de Calificación:</label>
              <b-form-select
                v-model="data.criteria"
                :state="data.criteria != null"
                :options="criteria"
                required
              ></b-form-select>
            </div>
            <div class="form-group">
              <label><b>Porcentaje:</b></label>
              <b-input-group prepend="%" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  type="number"
                  :state="
                    data.porcentual != null && verifyPorcentual(data.porcentual)
                  "
                  v-model="data.porcentual"
                  placeholder="Ingresar porcentaje"
                  required
                />
              </b-input-group>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="saveAddItem()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../../components/AlertError.vue";
export default {
  name: "CriteriaRegister",
  components: { AlertError },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      criteria: [],
      show: false,
      data: {},
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    verifyPorcentual(data) {
      if (data < 0 || data > 100) return false;
      else return true;
    },
    exit() {
      this.show = false;
    },
    add() {
      (this.data = {}), (this.show = true);
    },
    async initialize() {
      await this.$axios
        .get(
          "/dashboard/course/" + this.id + "/qualification-criteria-register",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.criteria = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async saveAddItem() {
      if (this.data.criteria == "" || this.data.porcentual == null) {
        this.$refs.error.showAlert("Ingrese todos los datos");
        return;
      }
      if (!this.verifyPorcentual(this.data.porcentual)) {
        this.$refs.error.showAlert("Fuera de los margenes porcentuales");
        return;
      }
      await this.$axios
        .post(
          "/dashboard/course/" + this.id + "/qualification-criteria-register",
          { id: this.data.criteria, porcentual: this.data.porcentual },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.$message.success(res.data.content);
          this.$parent.initialize();
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>