import { render, staticRenderFns } from "./Course-ListGrades-register.vue?vue&type=template&id=fc71b44e&"
import script from "./Course-ListGrades-register.vue?vue&type=script&lang=js&"
export * from "./Course-ListGrades-register.vue?vue&type=script&lang=js&"
import style0 from "./Course-ListGrades-register.vue?vue&type=style&index=0&id=fc71b44e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports