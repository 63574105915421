<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="card">
      <div class="card-header header-blue">
        <h2><b-icon-list-ol /> Lista de notas</h2>
      </div>
      <div class="card-body">
        <b-row>
          <b-col lg="3"
            ><button
              v-if="!disabledAttendance"
              @click="disabledAttendance = !disabledAttendance"
              style="background-color: #5e151d; color: white"
              class="btn mb-3 font-weight-bold w-100"
            >
              Ingresar notas
            </button>
            <button
              v-else
              @click="endRegister()"
              style="background-color: #5e151d; color: white"
              class="btn mb-3 font-weight-bold w-100"
            >
              Finalizar Registro
            </button>
          </b-col>
          <b-col lg="9" class="mb-3">
            <CriteriaRegister v-if="disabledAttendance" :id="id"
          /></b-col>
          <b-col></b-col>
        </b-row>
        <!--Tabla-->
        <b-overlay :show="showTable" rounded="sm">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Nombres</th>
                  <th
                    v-for="(data, i) in desserts.allQualificationDto"
                    :key="i"
                    scope="col"
                  >
                    {{ data.name + " (" + data.porcentual + "%) " }}
                    <b-icon-trash
                      @click="modalDelete(data)"
                      v-if="disabledAttendance"
                      type="button"
                      size="sm"
                      class="mx-1"
                    ></b-icon-trash>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, i) in desserts.allEnrollQualification"
                  :key="i"
                >
                  <th scope="row">
                    {{ i + 1 }}
                  </th>
                  <th scope="row">
                    {{
                      data.lastnameFather +
                      " " +
                      data.lastnameMother +
                      ", " +
                      data.name
                    }}
                  </th>
                  <td
                    v-for="(value, i) in data.allQualification"
                    :key="'C' + i"
                  >
                    <div v-if="disabledAttendance">
                      <b-input
                        class="inputCell"
                        type="number"
                        v-model="value.value"
                        @blur="addValue(data.id, value.id, value.value)"
                      />
                    </div>
                    <div v-else>
                      <p
                        :class="'font-weight-bold mb-0 text-' + value.color"
                        v-if="value.status != null"
                      >
                        {{ value.value }}
                      </p>
                      <p class="font-weight-bold mb-0" v-else>-</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-overlay>
        <!---->
      </div>
    </div>
    <!--Mensaje alerta eliminacion-->

    <div>
      <b-modal centered v-model="showDelete" title="Mensaje">
        <b-container fluid>
          <!--Contenido modal-->
          <h5 class="text-center">
            ¿Seguro que desea eliminar <b>{{ dataDelete.name }}</b> ?
            <p>
              <em>
                Se eliminarán todas las notas asociadas a este criterio de
                calificación.</em
              >
            </p>
          </h5>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="deleteCriteria()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
            <button
              type="button"
              @click="exitDelete()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import CriteriaRegister from "./Course-ListGrades-register.vue";
export default {
  components: {
    CriteriaRegister,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showTable: false,
      selected: "",
      dataDelete: {},
      showDelete: false,
      disabledAttendance: false,
      criteriaList: [],
      desserts: { allQualificationDto: [], allEnrollQualification: [] },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    alertUpdate() {
      alert("Mensaje");
      return "Te estás saliendo del sitio…";
    },
    endRegister() {
      this.disabledAttendance = !this.disabledAttendance;
      this.initialize();
    },
    exitDelete() {
      this.showDelete = false;
    },
    modalDelete(data) {
      this.dataDelete = {};
      this.showDelete = true;
      this.dataDelete = data;
    },
    formatData(data) {
      for (let i = 0; i < data.allEnrollQualification.length; i++) {
        for (
          let j = 0;
          j < data.allEnrollQualification[i].allQualification.length;
          j++
        ) {
          if (
            data.allEnrollQualification[i].allQualification[j].status == null
          ) {
            //Enviar "" no es lo mismo que " " para un campo numerico
            //" " pone un campo vacio, en cambio "" toma el valor que se tenia anteriormente
            data.allEnrollQualification[i].allQualification[j].value = " ";
          }
        }
      }
    },
    async addValue(id, id2, value) {
      this.showTable = true;
      await this.$axios
        .post(
          "/dashboard/course/" + this.id + "/qualification-edit",
          {
            personId: id,
            qualificationCriteriaId: id2,
            qualificationValue: value,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.initializeUpdate();
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async deleteCriteria() {
      await this.$axios
        .get(
          "/dashboard/course/" +
            this.id +
            "/qualification-criteria-delete?id=" +
            this.dataDelete.id,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.$message.success(res.data.content);
          this.initialize();
          this.showDelete = false;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/dashboard/course/" + this.id + "/list-qualification-criteria", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          this.formatData(this.desserts);
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async initializeUpdate() {
      await this.$axios
        .get("/dashboard/course/" + this.id + "/list-qualification-criteria", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          this.formatData(this.desserts);
          this.showTable = false;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async listCriterias() {
      await this.$axios
        .get(
          "/dashboard/course/" + this.id + "/qualification-criteria-register",
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.criteriaList = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.header-blue {
  background-color: #1d425e;
  color: white;
}
.inputCell {
  border: none;
  background-color: white;
}
</style>